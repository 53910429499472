import gql from 'graphql-tag'

export const queryCategoriesId = gql`
  query QueryCategoriesId($slug: [String], $firstCategories: Int = 10) {
    categories(where: { slug: $slug }, first: $firstCategories) {
      nodes {
        id
        categoryId
        slug
      }
    }
  }
`
