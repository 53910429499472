import gql from 'graphql-tag'

export const fragAuthorSummary = gql`
  fragment FragAuthorSummary on User {
    name
    description
    slug
    avatar {
      width
      height
      url
    }
  }
`
