export default {
  async mounted() {
    // Dichiaro le variabili prima di tutto
    await this.setTcVars()

    if (!this.isTcEnabled) {
      // Se non è presente nell'app carico lo script TC
      await this.$wrapper.addContainer(
        'container_head',
        'https://pixcimfrontend-s3-assets-pixcimfrontendassets091c-1fh6uhmvin9pk.s3.eu-west-1.amazonaws.com/assets/blog/tag/tc_Pixartprinting_16.js',
        'head'
      )
      await this.$wrapper.addContainer(
        'container_body',
        'https://pixcimfrontend-s3-assets-pixcimfrontendassets091c-1fh6uhmvin9pk.s3.eu-west-1.amazonaws.com/assets/blog/tag/tc_Pixartprinting_17.js',
        'body'
      )
      this.$store.commit('utils/setTcEnabled', true)
    } else if (window?.tC?.event) {
      // Se  è presente lo script TC aggiorno
      window.tC.event.routeChangeTcListener()
    }
  },
  computed: {
    isTcEnabled() {
      return this.$store.getters['utils/getTcEnabled']
    },
  },
  methods: {
    setTcVars() {
      if (!window?.tc_vars) {
        window.tc_vars = []
      }

      // Ritorno le categorie (1 e 2 da configurazione su tagcommander)
      const [cat1, cat2] = Array.from(
        this?.page?.postBy?.categories?.nodes ?? []
      ).filter(
        (category) =>
          // Rimuovo le categorie che non hanno pagine dedicate e nella configurazione sono segnalate in navigation/exclude
          !this.$configuration.navigation.exclude.includes(
            this.$getCategoryRoute(category.slug, 'wpSlug').key
          )
      )
      this.$wrapper.setTcVars({
        env_language: this.$i18n.locale,
        // Assegno le categorie
        page_cat1: cat1?.slug ?? '',
        page_cat1_id: cat1?.categoryId ?? '',
        page_cat2: cat2?.slug ?? '',
        page_cat2_id: cat2?.categoryId ?? '',
      })
    },
  },
  head() {
    const title = this.seo?.title
    return {
      title,
      ...this.$i18nHead(
        this.seo,
        this.$nuxtI18nHead({ addSeoAttributes: true })
      ),
    }
  },
}
