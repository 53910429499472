
export default {
  name: 'Flickity',
  directives: {
    flickity: {
      inserted(el, binding, vnode) {
        if (process.client) {
          const Flickity = require('flickity')
          vnode.context.flickity = new Flickity(el, binding.value)
          vnode.context.$emit('inserted')
        }
      },
      update(el, binding, vnode) {
        if (process.client && binding.value !== binding.oldValue) {
          vnode.context.flickity.destroy()
          const Flickity = require('flickity')
          vnode.context.flickity = new Flickity(el, binding.value)
          vnode.context.$emit('update')
        }
      },
    },
  },
  props: {
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    flickity: undefined,
  }),
  computed: {
    flickityOptions: {
      get() {
        return Object.assign({}, this.options, {
          on: {
            ready: () => this.$emit('ready'),
            change: () => this.$emit('change', this.flickity.selectedIndex),
            select: () => this.$emit('select'),
            dragMove: () => this.$emit('dragMove'),
            dragStart: () => {
              this.flickity
                .getCellElements()
                .forEach((slide) => (slide.style.pointerEvents = 'none'))
              this.$emit('dragStart')
              document.ontouchmove = (e) => e.preventDefault()
            },
            dragEnd: () => {
              this.flickity
                .getCellElements()
                .forEach((slide) => (slide.style.pointerEvents = 'all'))
              this.$emit('dragEnd')
              document.ontouchmove = () => true
            },
            pointerMove: () => this.$emit('pointerMove'),
            pointerDown: () => this.$emit('pointerDown'),
            pointerUp: () => this.$emit('pointerUp'),
            scroll: () => this.$emit('scroll'),
            staticClick: (event, pointer, cellElement, cellIndex) =>
              this.$emit('staticClick', {
                event,
                pointer,
                cellElement,
                cellIndex,
              }),
          },
        })
      },
    },
  },
  methods: {
    getIstance() {
      return this.flickity
    },
    goToSlide(i) {
      // console.log(i)
      this.flickity.select(i)
    },
  },
}
