import gql from 'graphql-tag'
import { fragPostSummary } from '../fragments/post.js'
import { fragPageInfo, fragSeoPostYoastFragment } from '../fragments/seo.js'
import { fragAuthorSummary } from '../fragments/author.js'
import { fragCptAdv } from '../fragments/advertising.js'

export const queryHome = gql`
  ${fragPostSummary}
  ${fragSeoPostYoastFragment}
  query QueryHome(
    $whereCategories: RootQueryToCategoryConnectionWhereArgs
    $uriPage: String
  ) {
    categories(where: $whereCategories) {
      nodes {
        slug
        posts {
          nodes {
            ...FragPostSummary
          }
        }
      }
    }
    pageBy(uri: $uriPage) {
      seo {
        ...FragSeoPostYoastFragment
      }
    }
  }
`

export const queryHomeIntegration = gql`
  ${fragSeoPostYoastFragment}
  ${fragAuthorSummary}
  ${fragPageInfo}
  ${fragPostSummary}
  ${fragCptAdv}
  query QueryHomeIntegration(
    $uriPage: String
    $firstPosts: Int = 15
    $firstUsers: Int
    $wherePosts: RootQueryToPostConnectionWhereArgs
    $whereCategories: RootQueryToCategoryConnectionWhereArgs
  ) {
    categories(where: $whereCategories) {
      nodes {
        slug
        posts {
          nodes {
            ...FragPostSummary
          }
        }
      }
    }
    pageBy(uri: $uriPage) {
      seo {
        ...FragSeoPostYoastFragment
      }
    }
    users(
      first: $firstUsers
      where: { hasPublishedPosts: POST, nicenameNotIn: "supervisor" }
    ) {
      edges {
        node {
          ...FragAuthorSummary
        }
      }
    }
    posts(first: $firstPosts, where: $wherePosts) {
      edges {
        node {
          ...FragPostSummary
        }
      }
      pageInfo {
        ...FragPageInfo
      }
    }
    pageBy(uri: $uriPage) {
      advertising {
        acfAdv {
          ... on Advertising {
            advertisingType {
              cptAdv {
                ...FragCptAdv
              }
            }
          }
        }
      }
    }
  }
`

/* export const queryHomeIntegration = gql`
  ${fragAuthorSummary}
  ${fragPageInfo}
  ${fragPostSummary}
  ${fragCptAdv}
  query QueryHomeIntegration(
    $uriPage: String
    $firstPosts: Int = 15
    $firstUsers: Int
    $wherePosts: RootQueryToPostConnectionWhereArgs
  ) {
    users(
      first: $firstUsers
      where: { hasPublishedPosts: POST, nicenameNotIn: "supervisor" }
    ) {
      edges {
        node {
          ...FragAuthorSummary
        }
      }
    }
    posts(first: $firstPosts, where: $wherePosts) {
      edges {
        node {
          ...FragPostSummary
        }
      }
      pageInfo {
        ...FragPageInfo
      }
    }
    pageBy(uri: $uriPage) {
      advertising {
        acfAdv {
          ... on Advertising {
            advertisingType {
              cptAdv {
                ...FragCptAdv
              }
            }
          }
        }
      }
    }
  }
`
*/
