
export default {
  name: 'Carousel',
  props: {
    authors: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    config: {
      groupCells: true,
      pageDots: false,
      prevNextButtons: false,
      cellAlign: 'left',
      adaptiveHeight: true,
    },
    flickity: undefined,
    selected: 0,
  }),
  mounted() {
    this.flickity = this.$refs.flickity.flickity
    // Ricalcolo le misure del carosello quando arrivano le props. Migliorabile
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'))
    }, 250)
  },
  methods: {
    onChange(index) {
      this.selected = index
    },
  },
}
