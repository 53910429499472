import gql from 'graphql-tag'

export const fragPageInfo = gql`
  fragment FragPageInfo on WPPageInfo {
    endCursor
    hasNextPage
    hasPreviousPage
    startCursor
  }
`

export const fragSeoCategoryYoastFragment = gql`
  fragment FragSeoCategoryYoastFragment on TaxonomySEO {
    twitterImage {
      sourceUrl(size: GG_GALLERY_THUMBNAIL)
    }
    twitterTitle
    twitterDescription
    title
    metaDesc
    opengraphTitle
    opengraphDescription
    opengraphType
    opengraphUrl
    opengraphSiteName
    opengraphImage {
      sourceUrl(size: GG_GALLERY_THUMBNAIL)
    }
  }
`

export const fragSeoPostYoastFragment = gql`
  fragment FragSeoPostYoastFragment on PostTypeSEO {
    twitterImage {
      sourceUrl(size: GG_GALLERY_THUMBNAIL)
    }
    twitterTitle
    twitterDescription
    title
    metaDesc
    opengraphTitle
    opengraphDescription
    opengraphType
    opengraphUrl
    opengraphSiteName
    opengraphImage {
      sourceUrl(size: GG_GALLERY_THUMBNAIL)
    }
  }
`

export const fragSeoUserYoastFragment = gql`
  fragment FragSeoUserYoastFragment on SEOUser {
    twitterImage {
      sourceUrl(size: GG_GALLERY_THUMBNAIL)
    }
    twitterTitle
    twitterDescription
    title
    metaDesc
    opengraphTitle
    opengraphDescription
    opengraphImage {
      sourceUrl(size: GG_GALLERY_THUMBNAIL)
    }
  }
`
