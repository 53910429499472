
import home from '@/mixins/home-data.js'
import seo from '@/mixins/seo.js'
import helpers from '@/mixins/helpers.js'

export default {
  // category ritorna page e seo data
  mixins: [home, seo, helpers],
  methods: {
    mergePosts(data) {
      const posts = this.page.posts
      this.page.posts = {
        edges: posts.edges.concat(data.posts.edges),
        pageInfo: data.posts.pageInfo,
      }
    },
  },
}
