import gql from 'graphql-tag'

export const fragCptAdv = gql`
  fragment FragCptAdv on Advertising_Advertisingtype_CptAdv {
    cptAdvLink
    cptAdvImage {
      sourceUrl
      srcSet
      sizes
      mediaDetails {
        height
        width
      }
    }
    cptAdvImageHorizontal {
      sourceUrl
      srcSet
      sizes
      mediaDetails {
        height
        width
      }
    }
  }
`
