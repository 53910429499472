import { queryCategoriesId } from '@/graphql/wordpress/query/categories.js'
import {
  queryHome,
  queryHomeIntegration,
} from '@/graphql/wordpress/query/home.js'

export default {
  async asyncData({ app, query, error, route }) {
    try {
      /**
       * 1. Ottengo i parametri della pagina e delle categorie dalla configurazione
       * 2. Ritorno gli id delle categorie per filtrare i vari listing
       * 3. Faccio la query
       */

      // Ritorno la configurazione della/e pagina/e così da ottenere seo, adv etc.
      const pageKey = 'index'
      const pageItem = app.$configuration.pages.list.find(
        (page) => pageKey === page.key
      )

      if (!pageItem) {
        console.error(`[${pageKey}] doesn't exist in the pages configuration`)
        return error({
          statusCode: 500,
        })
      }

      // Ritorno la configurazione delle categorie richieste nella pagina così da ottenere i relativi post
      const categoriesKeys = ['highlights', 'business-growth']
      const paramsList = app.$configuration.categories.list.filter((category) =>
        categoriesKeys.includes(category.key)
      )

      if (paramsList.length < categoriesKeys.length) {
        console.error(
          `[${categoriesKeys}] one or both keys doesn't exist in the categories configuration`
        )
        return error({
          statusCode: 500,
        })
      }
      // Ritorno gli ID delle categorie
      const categoriesIds = await app.apolloProvider.defaultClient
        .query({
          query: queryCategoriesId,
          variables: {
            slug: paramsList.map((params) => params.wpSlug),
          },
        })
        .then(({ data }) => data.categories.nodes)

      // Ritorno i contenuti delle categorie e della pagina associata. La query verrà integrata nel mounted per ottimizzare i tempi
      const requestHome = await app.apolloProvider.defaultClient
        .query({
          query: queryHome,
          variables: {
            whereCategories: {
              slug: categoriesIds.map(({ slug }) => slug),
              // slug: paramsList.map(({ wpSlug }) => wpSlug),
            },
            uriPage: pageItem.wpUri,
          },
        })
        .then(({ data }) => ({
          ...data,
          /*
              Da { categories: { nodes: [ [Object], [Object] ]  } }
              ritorno { categories: { 'business-growth': [Object], highlights: [Object] } }
            */
          categories: app.$reduceCategory(data.categories.nodes, paramsList),
        }))

      if (!requestHome.pageBy) {
        return error({ statusCode: 404 })
      }

      const page = requestHome

      return {
        page,
        categoriesIds,
        pageItem,
        paramsList,
      }
    } catch (e) {
      console.error(e)
      // return error({ statusCode: 500 })
    }
  },

  computed: {
    seo() {
      const seo = this.$generateTags(
        {
          ...this.page.pageBy.seo,
        },
        this.$i18n
      )
      return seo
    },
  },
  async mounted() {
    try {
      //  Dato che le query sono lente integro i dati del post con una query client side richiamando gli ADV e la descrizione che sono le parti più onerose per la query
      const requestHomeIntegration = await this.$apollo
        .query({
          query: queryHomeIntegration,
          variables: {
            firstUsers: 100,
            uriPage: this.pageItem.wpUri,
            firstPosts: 5,
            wherePosts: {
              categoryNotIn: this.categoriesIds.map(
                ({ categoryId }) => categoryId
              ),
            },
            whereCategories: {
              slug: this.categoriesIds.map(({ slug }) => slug),
              // slug: paramsList.map(({ wpSlug }) => wpSlug),
            },
            // categoryNotIn: paramsList.map(({ wpCategoryId }) => wpCategoryId),
          },
        })
        .then(({ data }) => ({
          ...data,
          /*
              Da { categories: { nodes: [ [Object], [Object] ]  } }
              ritorno { categories: { 'business-growth': [Object], highlights: [Object] } }
            */
          categories: this.$reduceCategory(
            data.categories.nodes,
            this.paramsList
          ),
        }))

      // Assegno gli ADV
      /* this.page = {
        ...this.page,
        users: requestHomeIntegration?.users,
        posts: requestHomeIntegration?.posts,
        pageBy: {
          ...this.page.pageBy,
          advertising: requestHomeIntegration?.pageBy?.advertising,
        },
      } */
      this.page = requestHomeIntegration
    } catch (e) {
      console.error(e)
      // return this.$nuxt.context.error({ statusCode: 500 })
    }
  },
}
